/* INPUT[type='range'] Shadow Elements Selectors *
 * ============================================= */
* {
	box-sizing: border-box;
}

body {
	font-family: 'Lato', sans-serif;
}

.circle-media-player {
	display: inline-block;
	padding: 0;
	margin: 0;
	border: 0;
	line-height: 0;
	background-color: transparent;
	fill: none;
	stroke: #D7DBDC;
	stroke-width: 3;
	cursor: pointer;
	outline: 0;
}

.circle-media-player__foreground {
	stroke: #6e9541;
	transition: 350ms stroke-dashoffset;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}

.circle-media-player__play,
.circle-media-player__pause {
	fill: #6e9541;
	stroke-width: 0;
}

.media-player-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 640px;
	margin-bottom: 24px;
}

.media-player {
	width: 100%;
	max-width: 640px;
	position: relative;
}

.media-player video::-webkit-media-controls {
	display: none !important;
}

.media-player-element {
	max-width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
	overflow: hidden;
	background-color: #d4d4d4;
}

.media-player-element video,
  .media-player-element iframe,
  .media-player-element object,
  .media-player-element embed {
	display: block;
	width: 100%;
	height: 300%;
	border: 0;
	position: absolute;
	top: -100%;
	left: 0;
	pointer-events: none;
}



.media-row {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.media-control-group {
	display: flex;
	align-items: center;
	position: relative;
}

.media-control-group--seek {
	flex: 1;
}

.media-control {
	margin: 0 12px;
}

.media-control--progress {
	-webkit-appearance: none;
	width: calc(100% - 24px);
	height: 3px;
	margin: 0 12px;
	border: 0;
	position: absolute;
	top: 7px;
	left: 12px;
	background-color: #373d3f;
	color: #434a4d;
}

.media-control--progress::-webkit-progress-bar {
	background-color: #373d3f;
}

.media-control--progress::-moz-progress-bar {
	background-color: #434a4d;
}

.media-control--progress::-webkit-progress-value {
	background-color: #434a4d;
}

.media-control--seekbar {
	position: relative;
	z-index: 5;
}

.media-control--seekbar::-webkit-slider-runnable-track {
	background-color: transparent !important;
}

.media-control--seekbar::-moz-range-track {
	background-color: transparent !important;
}

.media-control--seekbar::-ms-track {
	background-color: transparent !important;
}

.media-control--volume {
	max-width: 120px;
}

.media-control--prev-track {
	margin-right: 6px;
}

.media-control--next-track {
	margin-left: 6px;
}



@supports (-moz-appearance: meterbar) {
	input[type="range"] {
		overflow: hidden;
	}

	input[type="range"], input[type="range"]::before, input[type="range"]::after, input[type="range"] /deep/ *, input[type="range"] /deep/ *::before, input[type="range"] /deep/ *::after {
		box-sizing: border-box;
	}
}

_:-ms-input-placeholder,
  :root input[type="range"] {
	overflow: hidden;
}

_:-ms-input-placeholder, _:-ms-input-placeholder::before, _:-ms-input-placeholder::after, _:-ms-input-placeholder /deep/ *, _:-ms-input-placeholder /deep/ *::before, _:-ms-input-placeholder /deep/ *::after,
    :root input[type="range"],
    :root input[type="range"]::before,
    :root input[type="range"]::after,
    :root input[type="range"] /deep/ *,
    :root input[type="range"] /deep/ *::before,
    :root input[type="range"] /deep/ *::after {
	box-sizing: border-box;
}

input[type="range"]:not(*:root) {
	font-size: 1em;
}

@supports (-moz-appearance: meterbar) {
	input[type="range"] {
		font-size: 1em;
	}
}

_:-ms-input-placeholder,
  :root input[type="range"] {
	font-size: 1em;
}

input[type="range"]:not(*:root) {
	-webkit-appearance: none;
}

input[type="range"]:not(*:root):focus {
	outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
	overflow: visible;
}

input[type="range"]::-moz-range-track {
	overflow: visible;
}

input[type="range"]::-ms-track {
	overflow: visible;
}

input[type="range"]::-webkit-slider-runnable-track {
	-webkit-appearance: none;
	position: relative;
	z-index: 1;
}

input[type="range"]::-webkit-slider-runnable-track::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: inherit;
	height: inherit;
	border-radius: inherit;
	z-index: -1;
}

input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	position: relative;
	z-index: -1;
}

input[type="range"]::-webkit-slider-thumb::before {
	content: "";
	transform: translateX(-100%);
	display: block;
	z-index: -1;
}

input[type="range"]::-moz-range-track {
	z-index: -1;
}

input[type="range"]::-moz-focus-outer {
	border: 0;
}

input[type="range"]::-ms-track {
	border: none;
	color: transparent;
}

input[type="range"]::-ms-fill-lower {
	background: transparent;
}

input[type="range"]::-ms-tooltip {
	display: none;
}

input[type="range"]:hover::-webkit-slider-thumb, input[type="range"]:active::-webkit-slider-thumb {
	transform: scale(1.25);
}

input[type="range"]:hover::-moz-range-thumb, input[type="range"]:active::-moz-range-thumb {
	transform: scale(1.25);
}

input[type="range"]:hover::-ms-thumb, input[type="range"]:active::-ms-thumb {
	transform: scale(1.25);
}

input[type="range"]::-webkit-slider-runnable-track {
	width: 100%;
	height: 3px;
	border: 0;
	cursor: pointer;
	background-color: #373d3f;
}

input[type="range"]::-webkit-slider-runnable-track:active {
	cursor: grabbing;
}

input[type="range"]::-moz-range-track {
	width: 100%;
	height: 3px;
	border: 0;
	cursor: pointer;
	background-color: #373d3f;
}

input[type="range"]::-moz-range-track:active {
	cursor: grabbing;
}

input[type="range"]::-ms-track {
	width: 100%;
	height: 3px;
	border: 0;
	cursor: pointer;
	background-color: #373d3f;
}

input[type="range"]::-ms-track:active {
	cursor: grabbing;
}

input[type="range"]::-webkit-slider-container {
	background-size: inherit;
}

input[type="range"]::-webkit-slider-runnable-track {
	background-image: linear-gradient(#86b350, #86b350);
	background-size: inherit;
	background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb::before {
	background-color: #86b350;
}

input[type="range"]::-moz-range-progress {
	background-color: #86b350;
}

input[type="range"]::-ms-fill-lower {
	background-color: #86b350;
}

input[type="range"]::-webkit-slider-thumb {
	width: 8px;
	height: 8px;
	border: 0;
	border-radius: 50%;
	background-color: #cdd7db;
	cursor: grab;
	transform: scale(1);
	transform-origin: 50% 50%;
	transition: transform 150ms ease-out;
}

input[type="range"]::-webkit-slider-thumb:active {
	cursor: grabbing;
}

input[type="range"]::-moz-range-thumb {
	width: 8px;
	height: 8px;
	border: 0;
	border-radius: 50%;
	background-color: #cdd7db;
	cursor: grab;
	transform: scale(1);
	transform-origin: 50% 50%;
	transition: transform 150ms ease-out;
}

input[type="range"]::-moz-range-thumb:active {
	cursor: grabbing;
}

input[type="range"]::-ms-thumb {
	width: 8px;
	height: 8px;
	border: 0;
	border-radius: 50%;
	background-color: #cdd7db;
	cursor: grab;
	transform: scale(1);
	transform-origin: 50% 50%;
	transition: transform 150ms ease-out;
}

input[type="range"]::-ms-thumb:active {
	cursor: grabbing;
}

input[type="range"]::-webkit-slider-thumb {
	position: relative;
	top: -2.5px;
}

.media-playlist-header {
	padding: 2px;
	background-color: #373d3f;
}

.media-playlist-title {
	font-size: 14px;
	text-align: center;
}

.media-playlist {
	background-color: #282F31;
	color: #fff;
}

.media-playlist-tracks {
	padding: 0;
	margin: 0;
	list-style: none;
	border: 1px solid #373d3f;
}

.media-playlist-track {
	padding: 12px;
	cursor: pointer;
}

.media-playlist-track + .media-playlist-track {
	border-top: 1px solid #373d3f;
}

.media-playlist-track.is-active {
	color: #8bb955;
}