@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i,800,800i');
@import url('https://fonts.googleapis.com/css?family=Averia+Serif+Libre:300,300i,400,400i,700,700i');

body, html {
	font-size: 16px;
	margin: 0px;
	font-family: 'Open Sans', sans-serif !important;
	min-width: 320px;
}

* {
    box-sizing: border-box;
}